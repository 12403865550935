import { environment as base } from '@wingstop/environments/environment-base';

export const environment = {
  ...base,
  envName: 'perf',

  ping: {
    clientId: 'e6b0b8d2-9f45-4350-af34-b6d6346f3783',
    clientScope: 'openid ecomm_api',
    stsAuthority: 'https://nplogin.wingstop.com/as',
  },
  ecomm: {
    baseUrl: 'https://ecomm.perf.us.regional.uat.cf.10piececombo.com',
    menuUrl: 'https://ecomm.perf.us.regional.uat.cf.10piececombo.com',
    webSocketUrl: 'wss://websocket.perf.us.regional.uat.cf.10piececombo.com'
  },
  ngfeBaseUrl: 'https://perf.uat.ordering.wingstop.com',
};
